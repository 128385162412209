<template>
	<div>
		<!-- 头部导航 -->
		<el-affix :z-index='2001'>
		  <nav-bar></nav-bar>
		</el-affix>
		<!-- 工具栏 -->
		<tool-box></tool-box>
		<!-- 套餐内容 -->
		<div class='combo-wrap container'>
			<div class='course-info'>
				<el-row :gutter="15">
					<el-col :span='16'>
						<el-card class="box-card">
							<div class='course-detail'>
								<h3 class='course-title'>{{currentComboTabCon.title}}</h3>
								<div class='course-num'>
									<div class='num-left' v-if='currentCombo.period1==0'>
										有效期：{{formatTime(currentCombo.period1)}}
									</div>
									<div class='num-left' v-else>
										有效期：{{currentCombo.period1}}个月
									</div>
									<div class='num-right' ><span v-text="buyCount"></span>人报名</div>
								</div>
								<div class='course-spec'>
									<div class='choose-title'>科目选择</div>
									<div class='choose-content'>
										<ul v-if='currentComboClass.combo'>
											<li 
												@click='chooseSpec(item,index)'
												:class="specActive==index?'specTitleActive':''"
												v-for='(item,index) in currentComboClass.combo' 
												:key='index'>{{item.sub_title}}</li>
										</ul>
									</div>
								</div>
								<div class='course-tag'>
									<div class='choose-title'>课程特色</div>
									<div class='choose-content' v-if='currentComboClass.service'>
										<el-tag 
											class='combo-tag'
											type="info"
											:key='sindex'
											v-for="(sitem,sindex) in currentComboClass.service.split('/')">
											{{sitem}}</el-tag>
									</div>
								</div>
								<div class='course-teachers'>
									<div class='choose-title'>授课老师</div>
									<div class='choose-content'>
										<ul v-if='currentComboClass.teacher'>
											<li 
												v-for='(titem,tindex) in currentComboClass.teacher' 
												:key='tindex'> 
													<div class='teacher-portrait'>
													<el-image
													  style="width:35px; height: 35px"
													  :src="titem.thumb"
													  fit="scale-down"></el-image>
												</div>
												<span class='teacher-name'>{{titem.name}}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :span='8'>
						<el-card class="box-card">
							<div class='course-list'>
								<div>
									<div class='price'>
										￥<span>{{currentCombo.sale_price}}</span>
									</div>
									<div class='combo-grade-list'>
										<div class='grade-title'>
											<el-popover
												placement="bottom"
												:width="320"
												@show='showGradeList()'
												trigger="click">
												<template #reference>
													<div>
														课程列表<i class="el-icon-caret-bottom"></i>
													</div>
												</template>
												<!-- 班级列表 -->
												<div class='popover-content'>
													<div class='grade-list'
														:key='index'
														v-for='(item,index) in gradeList.course'>
														<div class='list-title'>{{item.title}}</div>
														<ul class='list-content'>
															<li 
																:key='gindex'
																v-for='(gitem,gindex) in item.grades'>
																	{{gitem.title}}
															</li>
														</ul>
													</div>
												</div>
											</el-popover>
										</div>
										<div class='combo-img'>
											<img :src="currentCombo.thumb" alt="">
										</div>
									</div>
								</div>
								<div class='combo-handdle'>
									<el-button 
										class='handdle-btn'
										type="danger"
										@click='toCreateOrder()'
										size="medium">立即报名</el-button>
								</div>
							</div>
						</el-card>
					</el-col>
				</el-row>
			</div>
			<div class='course-combo'>
				<el-row :gutter="15">
					<el-col :span='16'>
						<el-card class="box-card">
							<div class='combo-detail'>
								<div class='tab-title'>
									<ul>
										<li @click='tabTitleActive=0'>
											<span 
												:class="tabTitleActive==0?'tabtitleActive':''"
												class='title-inner'>课程详情</span>
										</li>
										<li @click='tabTitleActive=1'>
											<el-badge value="试听" :type='$config.buttonType'>
											  <span
											  	:class="tabTitleActive==1?'tabtitleActive':''"
											  	class='title-inner'>课程目录</span>
											</el-badge>
										</li>
										<li @click='tabTitleActive=2'>
											<span
												:class="tabTitleActive==2?'tabtitleActive':''"
												class='title-inner'>授课老师</span>
										</li>
									</ul>
								</div>
								<div class='tab-content' v-show='tabTitleActive==0'>
									<div 
										class='desc-container' 
										v-html='currentCombo.content'></div>
								</div>
								<div class='tab-content' v-show='tabTitleActive==1'>
									<div class='list-container'>
										<div class='list-tabs'>
											<div 
												class='tabs-content' 
												v-if='currentComboTabCon'>
												<div 
													class='combo-list'
													:key='tindex'
													v-for='(titem,tindex) in currentComboTabCon.course'>
													<div class='list-title'>{{titem.title}}</div>
													<ul class='list-content'>
														<li 
														:key='gindex'
														@click='toCoursePlayer(gitem)'
														v-for='(gitem,gindex) in titem.grades'>
															<span>{{gitem.title}}</span>
															<div class='listen'>
																<span>试听</span>
																<svg-icon
																	icon-class='shipin' 
																	class='icon-listen'></svg-icon>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class='tab-content' v-show='tabTitleActive==2'>
									<div 
										class="teacher-container" 
										v-if="comboTeachers">
										<el-row :gutter="15">
											<el-col 
												:span="8" 
												@click.stop="toTeacherDetail(teacher)"
												v-for="(teacher,index) in comboTeachers" 
												:key="index">
												<el-card class='box-card'>
													<div class="teacher-card">
														<div class="top">
															<div class="image">
																<img :src="teacher.thumb">
															</div>
															<div class="content">
																<span 
																	class="title">
																	{{teacher.name}}
																</span>
																<br>
																<div class="bottom">
																	<el-button 
																		@click.stop="toTeacherDetail(teacher)"
																		:type="$config.buttonType" 
																		size="mini">查看详情</el-button>
																</div>
															</div>
														</div>
														<div class="bottom">
															<div>{{stripHTML(teacher.content)}}</div>
														</div> 
													</div>
												</el-card>
											</el-col>
									  </el-row>
									</div>
									<div v-else>
									  <el-empty description="暂无老师信息"></el-empty>
									</div>
								</div>
							</div>
						</el-card>
					</el-col>
					<el-col :span='8'>
						<div class='combo-relate'>
							<div class='relate-title'>
								<span>相关课程</span>
							</div>
							<div class='relate-content'>
								<div 
									class='relate-item'
									v-for='(item,index) in relateList' 
									:key='index'>
									<comboclass-item :item='item'></comboclass-item>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- footer -->
		<web-footer></web-footer>
	</div>
</template>

<script>
	import NavBar from '@/components/page/NavBar'
	import ToolBox from '@/components/toolbox'
	import WebFooter from '@/components/page/WebFooter'
	
	import comboclassItem from './components/ComboClassItem'
	
	import {comboByCourse,getComboGrade,getComboBySpec} from '@/api/course'
	import {formatTime} from '@/utils/index'
	
	import {mapState,mapMutations} from 'vuex';
	import {getSessionStorage,setSessionStorage,getLocalStorage,setLocalStorage,removeLocalStorage} from '@/utils/auth'
	
	import {stripHTML} from '@/utils/index'
	export default{
		name:'courseDetail',
		props:['specid','comboclassid','comboid'],//科目，班型，套餐
		components: {
		  NavBar,
		  ToolBox,
		  WebFooter,
		  comboclassItem
		},
		data(){
			return {
				comboList:[],//套餐列表
				specActive:0 ,//当前科目
				tabActive:0,//课程目录当前科目
				allComboClass:[],//所有班型
				currentComboClass:{},//当前套餐类型
				currentCombo:{},//当前套餐
				currentComboTabCon:{},//当前课程目录
				gradeList:{},//课程列表
				showGradeFlag:false,//是否显示课程列表
				tabTitleActive:0,
				relateList:[],//相关推荐
				comboClassItem:{},
				buyCount: 0,//报名人数
			}
		},
		watch:{
			$route(to,from){
				getComboBySpec({
					spec_id:this.specid
				}).then(res=>{
					if(res.code==1){
						//相关课程
						this.relateList = res.data.comboclass;
						//所有班型
						this.allComboClass = res.data.comboclass;
						
						let allComboClass = res.data.comboclass;
						allComboClass.map((item,index)=>{
							if(item.id == this.comboclassid){
								this.currentComboClass = item;//当前套餐班型
								this.comboList = item.combo;//套餐列表
								this.comboTeachers = item.teacher;//讲师列表
								item.combo.map((citem,cindex)=>{
									if(citem.id==this.comboid){
										this.currentCombo = citem;
										this.specActive = cindex;
									}
								})
								//默认课程目录内容
								getComboGrade({
									id:this.currentCombo.id
								}).then(res=>{
									if(res.code==1){
										this.currentComboTabCon = res.data;
									}
								});
							}
						})
					}
				})
			}
		},
		computed:{
			...mapState(['token'])
		},
		created() {
			getComboBySpec({
				spec_id:this.specid
			}).then(res=>{
				if(res.code==1){
					//相关课程
					this.relateList = res.data.comboclass;
					//所有班型
					this.allComboClass = res.data.comboclass;
					
					let allComboClass = res.data.comboclass;
					allComboClass.map((item,index)=>{
						if(item.id == this.comboclassid){
							this.currentComboClass = item;//当前套餐班型
							this.comboList = item.combo;//套餐列表
							this.comboTeachers = item.teacher;//讲师列表
		
							item.combo.map((citem,cindex)=>{
								if(citem.id==this.comboid){
									this.currentCombo = citem;
									this.specActive = cindex;
								}
							})
							//默认课程目录内容
							getComboGrade({
								id:this.currentCombo.id
							}).then(res=>{
								if(res.code==1){
									this.currentComboTabCon = res.data;
									this.buyCount = this.currentComboTabCon.buy_count;
								}
							});
						}
					})
				}
			})
		},
		methods:{
			stripHTML,
			formatTime,
			showGradeList(){//课程列表
				getComboGrade({
					id:this.currentCombo.id
				}).then(res=>{
					if(res.code==1){
						this.gradeList = res.data;
					}
				});
			},
			chooseSpec(item,index){//选择科目
				this.specActive = index;
				this.currentCombo = item;
				getComboGrade({
					id:item.id
				}).then(res=>{
					if(res.code==1){
						this.currentComboTabCon = res.data;
						this.buyCount = this.currentComboTabCon.buy_count;
					}
				})
			},
			toCoursePlayer(gitem){//试听
				//把播放当前班存入会话
				setSessionStorage('currentGradePlayer',gitem);
				let comboid = this.currentComboTabCon.id;
				let gradeid = gitem.id;
				//当前试听，章节id默认为0；
				this.$router.push({
					path:'/courseplayer/'+comboid+'/'+gradeid+'/0'
				});
			},
			toTeacherDetail(data){
				this.$router.push('/teacherdetail/' + data.id)
			},
			toCreateOrder(){//创建订单
				if(getLocalStorage('token')){
					let comboid = this.currentCombo.id;
					setSessionStorage('createOrderInfo',this.currentCombo)
					this.$router.push({
						path:'/order/'+comboid + '/1'
					});
				}else{
					this.$notify.error({
					  title: '未登录',
					  message: '请先登录'
					});
					return;
				}
			}
		}
	}
</script>
<style scoped="scoped" lang="scss">
	.popover-content{
		.grade-list{
			.list-title{
				font-size:16px;
				font-weight: bold;
				padding:3px 0px;
			}
			.list-content{
				li{
					padding:3px 0px;
					cursor: pointer;
				}
				li:hover{
					color:$theme-color;
				}
			}
		}
	}
	.combo-wrap{
		margin-top:15px;
		.course-info{
			.course-detail{
				padding:20px;
				height:350px;
				box-sizing: border-box;
				overflow: hidden;
				.course-title{
					font-size:18px;
					font-weight: bold;
				}
				.course-num{
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					margin:25px 0px;
					color:$info-color;
					.num-right{
						color:#333;
					}
				}
				.course-spec{
					display: flex;
					flex-direction: row;
					.choose-title{
						font-size:16px;
						width:100px;
					}
					.choose-content{
						flex:1;
						max-height:90px;
						overflow:auto;
						box-sizing: border-box;
						ul{
							display: flex;
							flex-direction: row;
							align-items: center;
							flex-wrap: wrap;
							li{
								padding:5px 20px;
								margin-right:15px;
								margin-bottom:15px;
								border:1px solid $info-color;
							}
							li.specTitleActive{
								border:1px solid $theme-color;
								background-color: $theme-color;
								color:#fff;
							}
						}
					}
				}
				.course-tag{
					margin:10px 0px 25px 0px;
					display: flex;
					flex-direction: row;
					.choose-title{
						font-size:16px;
						width:100px;
					}
					.choose-content{
						flex:1;
						.combo-tag{
							margin-right:10px;
						}
					}
				}
				.course-teachers{
					display: flex;
					flex-direction: row;
					.choose-title{
						font-size:16px;
						width:100px;
					}
					.choose-content{
						flex:1;
						ul{
							display: flex;
							flex-direction: row;
							align-items: center;
							li{
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								margin-right:20px;
								font-size:12px;
								.teacher-portrait{
									width:35px;
									height:35px;
									border-radius:100%;
									overflow: hidden;
									border:1px solid #eee;
									margin-bottom: 3px;
								}
							}
						}
					}
				}
			}
			.course-list{
				padding:20px;
				height:350px;
				box-sizing: border-box;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.price{
					color:$price-color;
					span{
						font-size:20px;
					}
					margin-bottom:15px;
				}
				.combo-grade-list{
					overflow:auto;
					.grade-title{
						font-size:16px;
						margin-bottom:5px;
						padding:5px 0px 5px 10px;
						background-color:#f4f4f5;
					}
				}
				.combo-handdle{
					margin-top:10px;
					width:100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					.handdle-btn{
						width:100%;
					}
				}
				
			}
		}
		.course-combo{
			margin-top:15px;
			.combo-detail{
				padding:20px;
				.tab-title{
					ul{
						display: flex;
						flex-direction: row;
						align-items: center;
						li{
							flex:1;
							margin:0px 10px;
							text-align: center;
							font-size:18px;
							.title-inner{
								padding:5px 0px;
							}
							.title-inner.tabtitleActive{
								font-weight: bold;
								color:$theme-color;
								border-bottom:2px solid $theme-color;
							}
						}
					}
				}
				.tab-content{
					margin-top:20px;
					padding:10px 0px;
					border-top:1px solid #ccc;
					.desc-container{
						
					}
					.list-container{
						.list-tabs{
							width:100%;
							.tabs-title{
								display: flex;
								flex-direction: row;
								align-items: center;
								flex-wrap:wrap;
								font-size:16px;
								overflow: hidden;
								li{
									padding:2px 15px;
									margin-right:10px;
									margin-bottom:10px;
									border:1px solid $info-color;
								}
								li.tabTitleActive{
									border:1px solid $theme-color;
									background-color: $theme-color;
									color:#fff;
								}
							}
							.tabs-content{
								.combo-list{
									margin:10px 0px;
									.list-title{
										font-size:16px;
										font-weight: bold;
										padding:5px 0px;
									}
									.list-content{
										padding:0px 10px;
										li{
											padding:5px 0px;
											display: flex;
											flex-direction: row;
											justify-content: space-between;
											align-items: center;
											.listen{
												font-size:12px;
												color:$theme-color;
												.icon-listen{
													font-size:14px;
												}
											}
											
										}
										li:hover{
											color:$theme-color;
										}
									}
								}
							}
						}
					}
					.box-card{
						margin-bottom:15px;
					}
					.teacher-container{
					  .teacher-card{
					    display: flex;
					    flex-direction:column;
					    height:310px;
					    .top{
					      height:200px;
					      padding:14px;
					      display: flex;
					      flex-direction: row;
					      justify-content: space-between;
					      .image {
					        width:150px;
					        height:200px;
					        overflow: hidden;
					        img{
					          width:100%;
					        }
					      }
					      .content{
					        width:100px;
					        display: flex;
					        flex-direction: column;
					        align-items: center;
					        justify-content: flex-end;
					        .title{
					          font-size:16px;
					          font-weight:400;
					        }
					      }
					    }
					    .bottom {
					      padding:0px 10px 10px 10px;
					      height:60px;
					      margin-bottom:10px;
					      overflow: hidden;
					      line-height: 22px;
					    }
					  }
					}
				}
			}
			.combo-relate{
				.relate-title{
					font-size:18px;
					height:60px;
					display: flex;
					flex-direction: row;
					align-items: center;
					span{
						padding-left:5px;
						border-left:3px solid $theme-color;
					}
				}
				.relate-content{
					.relate-item{
						margin-bottom: 15px;
					}
				}
			}
		}
	}
	
</style>
